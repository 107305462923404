import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useComponentesPorElemento, useElementosPorRecinto, useFallasPorComponente, useImagenesPorRequerimiento, useRecintosPorUnidad } from "../../customHooks/postventa";

import CloseButtonIcon from "../Icons/CloseButtonIcon/CloseButtonIcon";
import FileUploadIcon from "@material-ui/icons/CloudUpload"

import { colorConfig } from '../../constants/colors';
import './NewSolicitud.scss'
import { agregarImagenARequerimiento, agregarRequerimiento, agregarSolicitud, modificarImagenARequerimiento, modificarRequerimiento, notificarNuevoRequerimiento } from "../../services/postventaService";
import { GlobalContext } from '../../providers/GlobalStateProvider';

import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline'
import HighlightOff from "@material-ui/icons/HighlightOff";


const capitalize = (word) => {
  const phrase = word.split(' ').map(el => {
    const sep = el.split('-')
                  .map(le => {return le.charAt(0).toUpperCase() + le.toLowerCase().slice(1)})
                  .join('-')
                  .split('/')
                  .map(le => {return le.charAt(0).toUpperCase() + le.toLowerCase().slice(1)})
                  .join('/')
    return sep
  }).join(' ')
  return phrase
}

const readImage = image => {
  const fr = new FileReader()
  fr.onload =
  fr.readAsDataURL(image)
  return fr
}

const NewSolicitud  = ({subsidiary, unidad, unidadId, handleClose, titulo, solicitud, fechaEntrega, fechaMunicipal, requirement, onNewSolicitud, cliente, proyecto, nombreUnidad, requerimientos, onChangeRequerimientos, additional}) => {

  const { recintosBack } = useRecintosPorUnidad(unidad)
  const [recintos, setRecintos] = useState([recintosBack]);

  const [ recintoId, setRecintoId ] = useState(0)
  const { elementosBack, setElementosBack } = useElementosPorRecinto(recintoId)
  const [ elementos, setElementos ] = useState([])

  const [ elementoid, setElementoId ] = useState(0)
  const { componentes, setComponentes } = useComponentesPorElemento(elementoid)

  const [ relElemRecinto, setRelElemRecinto ] = useState(0);

  const [ componenteid, setComponenteId ] = useState(0)
  const { fallas, setFallas } = useFallasPorComponente(componenteid)

  const [ fallaid, setFallaId ] = useState(0)
  const [ reqId, setReqId ] = useState(requirement?.requerimientoId)

  const { imagenes } = useImagenesPorRequerimiento(reqId)
  const [ nuevoReq, setNuevoReq ] = useState()
  const [ forSave, setForSave ] = useState(false)
  const { state: { username = '', fullName = '', idAdditional = '' } = {} } = useContext(GlobalContext);
  
  const [ notificacion, setNotificacion ] = useState({
    rut: username,
    solicitud: {
      nroSolicitud: solicitud?.nroSolicitud,
      solicitante: fullName,
      numeroProducto: nombreUnidad,
      proyecto: proyecto,
      requerimientos: [...requerimientos, nuevoReq]
    }
  })

  useEffect(() => {
    if (nuevoReq?.recintoEspecifico && nuevoReq?.elemento && nuevoReq?.componente && nuevoReq?.falla && nuevoReq?.descripcionRequerimiento) {
      setForSave(true)
    }
    setNotificacion({
      rut: username,
      solicitud: {
        nroSolicitud: solicitud?.nroSolicitud,
        solicitante: fullName,
        numeroProducto: nombreUnidad,
        proyecto: proyecto,
        requerimientos: [...requerimientos, {...nuevoReq, nroRequerimiento: requerimientos.length + 1}]
      }
    })
    onChangeRequerimientos({
      rut: username,
      solicitud: {
        nroSolicitud: solicitud?.nroSolicitud,
        solicitante: fullName,
        numeroProducto: nombreUnidad,
        proyecto: proyecto,
        requerimientos: [...requerimientos, {...nuevoReq, nroRequerimiento: requerimientos.length + 1}]
      }
    })
  }, [nuevoReq])

  const handleRecintos = (event) => {
    setRecintoId(0)
    setElementos([])
    setComponentes([])
    setFallas([])
    setRecintoId(event.target.value)
    const rec = recintos.find(recinto => recinto.RecintoEspecificoID === event.target.value)
    setNuevoReq({...nuevoReq, recintoEspecifico: rec.RecintoEspecificoNombre})
    setElementoId(0)
    setComponenteId(0)
    setFallaId(0)
  }
  const handleElementos = (event) => {
    setComponentes([])
    setFallas([])
    setElementoId(event.target.value)
    const elem = elementos.find(elemento => elemento.ElementoID === event.target.value)
    setNuevoReq({...nuevoReq, elemento: elem.ElementoNombre})
    setComponenteId(0)
    setFallaId(0)
  }
  useEffect(() => {
    if (elementoid !== 0) {
      const rel = elementosBack.filter(el => el.ElementoID === elementoid)[0]
      setRelElemRecinto(rel.RecintoEspecificoRelElementoID)
    }
  }, [elementoid])
  const handleComponentes = (event) => {
    setFallas([])
    setComponenteId(event.target.value)
    const comp = componentes.find(componente => componente.ComponenteID === event.target.value)
    setNuevoReq({...nuevoReq, componente: comp.ComponenteNombre})
    setFallaId(0)
  }
  const handleFallas = (event) => {
    setFallaId(event.target.value)
    const falla = fallas.find(falla => falla.FallaID === event.target.value)
    setNuevoReq({...nuevoReq, falla: falla.FallaNombre})
  }
  const [imagen, setImagen] = useState()
  const [img, setImg] = useState()
  const handleFileUpload = event => {
    const files = event.target.files;
    if (files.length > 0) {
      setImg(files[0])
      const img = readImage(files[0])
      img.onload = () => {
        setImagen(`${img.result}?${Math.random()}`)
      }
    }
  }
  const [descripcion, setDescripcion] = useState(requirement?.descripcionRequerimiento)
  const maxCaracteres = 200;
  const [caracteresRestantes, setCaracteresRestantes] = useState(maxCaracteres - descripcion?.length);

  if (!caracteresRestantes && caracteresRestantes !== 0) {
    setCaracteresRestantes(maxCaracteres)
  }

  const handleDescripcion = (event) => {
    const nuevaDescripcion = event.target.value;
    setDescripcion(nuevaDescripcion);
    setCaracteresRestantes(maxCaracteres - nuevaDescripcion.length);
    setNuevoReq({ ...nuevoReq, descripcionRequerimiento: nuevaDescripcion });
  };

  const [idDelegado, setIdDelegado] = useState(57);
  const [tipoSolicitud, setTipoSolicitud] = useState(1);
  const [idSolicitud, setIdSolicitud] = useState(solicitud?.solicitudId);

  useEffect(() => {
    const nuevo = titulo.split(' ')[1]
    if (nuevo === 'solicitud') setIdSolicitud(undefined)
  }, [])

  const crearRequerimiento = () => {
    handleClose()
    if (!idSolicitud) {
      agregarSolicitud(username, additional, idDelegado, unidadId, tipoSolicitud, idAdditional,
        ({data})=>{
          const idSolicitud = data.solicitud.SolicitudId
          setIdSolicitud(idSolicitud)
          setNotificacion({...notificacion, solicitud: {...notificacion.solicitud, nroSolicitud: idSolicitud}})
          agregarRequerimiento(idSolicitud, unidadId, recintoId, relElemRecinto, componenteid, fallaid, descripcion,
            ({data})=>{
              const id_requerimiento = data?.requerimiento?.RequerimientoNewID
              // notificarNuevoRequerimiento(notificacion, ({data})=>{console.log(data)}, (error)=>{console.error(error)})
              if (id_requerimiento && img) {
                setReqId(id_requerimiento)
                agregarImagenARequerimiento(id_requerimiento, img,
                  ({data}) => {
                    onNewSolicitud()
                  },
                  err => {
                    console.error({err})
                    onNewSolicitud()
                  }
                )
              } else {
                onNewSolicitud()
              }
            },
            err =>{
              console.error(err)
              onNewSolicitud()
            }
            )
        },
        err =>{
          console.error(err)
          onNewSolicitud()
        })
    } else {
      if (requirement) {
        modificarRequerimiento(reqId, recintoId, relElemRecinto, componenteid, fallaid, descripcion, (data) => {
          if (reqId && img) {
            modificarImagenARequerimiento(reqId, img,
                ({data}) => handleClose(),
                (err) => console.error({err})
              )
          }
          handleClose()
        }, (err)=> {
          console.error({err})
          handleClose()
        })
      } else {
        agregarRequerimiento(idSolicitud, unidadId, recintoId, relElemRecinto, componenteid, fallaid, descripcion,
          ({data})=>{
            const id_requerimiento = data?.requerimiento?.RequerimientoNewID
            // notificarNuevoRequerimiento(notificacion, ({data})=>{console.log(data)}, (error)=>{console.error(error)})
            if (id_requerimiento && img) {
              setReqId(id_requerimiento)
              agregarImagenARequerimiento(id_requerimiento, img,
                ({data}) => {
                  handleClose()
                },
                err => {
                  console.error({err})
                  handleClose()
                }
              )
            } else {
              handleClose()
            }
          },
          err =>{
            console.error(err)
            handleClose()
          }
          )
      }
    }
  }

  useEffect(()=>{
    if (recintosBack && recintosBack.length > 0) {
      const thisRecinto = recintosBack.find(recinto => recinto.RecintoEspecificoNombre.toUpperCase() === requirement?.recintoEspecifico?.toUpperCase())
      const RecintoEspecificoID = thisRecinto?.RecintoEspecificoID || ''
      setRecintoId(RecintoEspecificoID || 0)
      setRecintos(recintosBack.map(recinto => {
        return {
          ...recinto,
          selected: recinto.RecintoEspecificoID === RecintoEspecificoID
        }
      }))
    }
  },[requirement, recintosBack])

  useEffect(()=>{
    if (imagenes && imagenes.length > 0){
      setImg(imagenes[0].url)
      setImagen(`${imagenes[0].url}?${Math.random()}`)
    }
  },[imagenes])

  useEffect(()=>{
    const elemento = elementosBack.find(elemento => elemento.ElementoNombre.toUpperCase() === requirement?.elemento?.toUpperCase())
    setElementoId(elemento?.ElementoID || 0)
    if (elementosBack && elementosBack.length > 0) {
      const thisElemento = elementosBack.find(elem => elem.ElementoNombre.toUpperCase() === requirement?.elemento?.toUpperCase())
      const ElementoID = thisElemento?.ElementoID
      setElementos(elementosBack.map(elem => {
        return {
          ...elem,
          selected: elem.ElementoID === ElementoID
        }
      }))
    }
  },[requirement, elementosBack])
  useEffect(()=>{
    const componente = componentes.find(componente => componente.ComponenteNombre.toUpperCase() === requirement?.componente?.toUpperCase())
    setComponenteId(componente?.ComponenteID || 0)
  },[requirement, componentes])
  useEffect(()=>{
    const falla = fallas.find(falla => falla.FallaNombre.toUpperCase() === requirement?.falla?.toUpperCase())
    setFallaId(falla?.FallaID || 0)
  },[requirement, fallas])

  useEffect(()=>{
    if (recintoId && elementoid && componenteid && fallaid && descripcion) {
      setForSave(true)
    } else {
      setForSave(false)
    }
  },[recintoId, elementoid, componenteid, fallaid, descripcion])

  return (
    <>
        <IconButton
          className='requirement-view__close-button'
          onClick={handleClose}
          style={{position: 'absolute'}}
        >
            <CloseButtonIcon color={colorConfig[subsidiary].icons} />
        </IconButton>
        <div className="Solicitud">
          <div className="Solicitud__titulo">
              <span>{titulo}</span>
          </div>
          <div className="Solicitud__requerimiento">
            <div className="Solicitud__requerimiento__imagen">
              <Button component='label' variant="contained" className="Solicitud__requerimiento__imagen__button">
                <input type='file' hidden accept="image/png, image/jpeg" onClick={handleFileUpload} onChange={handleFileUpload} />
                {img  && typeof img === 'string' ?
                  <picture>
                    <img src={img} className='Solicitud__requerimiento__imagen__img' alt="Imagen del requerimiento" ></img>
                  </picture>
                  : img && typeof img === 'object' ?
                  <picture>
                    <img src={URL.createObjectURL(img)} className='Solicitud__requerimiento__imagen__img' alt="Imagen del requerimiento" />
                  </picture>
                  :
                  <FileUploadIcon color={colorConfig[subsidiary].icons} />
                }
              </Button>
            </div>
            <div className="Solicitud__requerimiento__formulario">
                <div className="Solicitud__requerimiento__formulario__input">
                    <FormControl variant="standard">
                        <InputLabel id='recintos'>Recinto</InputLabel>
                        <Select
                          labelId="recintos"
                          id='selectRecintos'
                          value={recintoId}
                          label='Recintos'
                          onChange={handleRecintos}
                        >
                          {/* <MenuItem value='0'>Elemento</MenuItem> */}
                          {recintosBack.map(recinto => {
                            return <MenuItem selected={requirement?.recintoEspecificoId == recinto.RecintoEspecificoID} value={recinto.RecintoEspecificoID}>{capitalize(recinto.RecintoEspecificoNombre)}</MenuItem>
                            })
                          }
                        </Select>
                    </FormControl>
                </div>
                <div className="Solicitud__requerimiento__formulario__input">
                    <FormControl variant="standard">
                        <InputLabel id='elementos'>Elemento</InputLabel>
                        <Select
                          labelId="elementos"
                          id='selectElementos'
                          value={elementoid}
                          label='Elementos'
                          onChange={handleElementos}
                        >
                          {/* <MenuItem value='0'>Elemento</MenuItem> */}
                          {elementos.map(elemento => {
                            return <MenuItem selected={requirement?.elementoId === elemento.ElementoID} value={elemento.ElementoID}>{capitalize(elemento.ElementoNombre)}</MenuItem>
                            })
                          }
                        </Select>
                    </FormControl>
                </div>
                <div className="Solicitud__requerimiento__formulario__input">
                    <FormControl variant="standard">
                        <InputLabel id='componentes'>Componente</InputLabel>
                        <Select
                          labelId="componentes"
                          id='selectComponentes'
                          value={componenteid}
                          label='Componente'
                          onChange={handleComponentes}
                        >
                          {/* <MenuItem value='0'>Componente</MenuItem> */}
                          {componentes.length > 0 && componentes.map(componente => {
                            return <MenuItem selected={requirement?.componenteId === componente.ComponenteID} value={componente.ComponenteID}>{capitalize(componente.ComponenteNombre)}</MenuItem>
                            })
                          }
                        </Select>
                    </FormControl>
                </div>
                <div className="Solicitud__requerimiento__formulario__input">
                    <FormControl variant="standard">
                      <InputLabel id='fallas'>Falla</InputLabel>
                        <Select
                          labelId="fallas"
                          id='selectFallas'
                          value={fallaid}
                          label='Fallas'
                          onChange={handleFallas}
                        >
                          {/* <MenuItem value='0'>Falla</MenuItem> */}
                          {fallas.length > 0 && fallas.map(falla => {
                            return <MenuItem selected={requirement?.fallaId === falla.FallaID} value={falla.FallaID}>{capitalize(falla.FallaNombre)}</MenuItem>
                            })
                          }
                        </Select>
                    </FormControl>
                </div>
                <div className="Solicitud__requerimiento__formulario__input">
                    <InputLabel id='descripcion'>Descripción</InputLabel>
                    <TextField
                        id='Detalle'
                        labelId='descripcion'
                        multiline
                        rows={3}
                        variant="standard"
                        onChange={handleDescripcion}
                        value={descripcion}
                        inputProps={{ maxLength: maxCaracteres }}
                    />
                    <div>
                      <small>{caracteresRestantes} caracteres restantes</small>
                    </div>
                </div>
            </div>
          </div>
          <div className="Solicitud__garantias">
            <div className="Solicitud__fecha">
              <span><strong>Fecha de Entrega: </strong>{fechaEntrega}</span>
            </div>
            <div className="Solicitud__fecha">
              <span><strong>Fecha Recepción Municipal: </strong>{fechaMunicipal}</span>
            </div>
          </div>
          <div className="Solicitud__buttons">
            <Button
                variant="contained"
                style={{
                    backgroundColor: forSave ? colorConfig[subsidiary].primary : '#cccccc',
                    color: forSave ? colorConfig[subsidiary].secondary : 'rgba(0, 0, 0, 0.26)', // Asumiendo que quieres cambiar también el color del texto
                    textTransform: 'none',
                    border: !forSave ? '1px solid #cccccc' : 'none', // Opcional, en caso de que quieras agregar un borde gris cuando está deshabilitado
                }}
                onClick={crearRequerimiento}
                disabled={!forSave}
            >
                <CheckCircleIcon color={forSave ? colorConfig[subsidiary].icons : 'rgba(0, 0, 0, 0.26)'} style={{marginRight: '5px'}} />
                Grabar
            </Button>
              <Button
                  style={{color: colorConfig[subsidiary].primary, border: colorConfig[subsidiary].primary + ' solid', textTransform: 'none'}}
                  onClick={handleClose}
              >
                  <HighlightOff color={colorConfig[subsidiary].icons} style={{marginRight: '5px'}} />
                  Cancelar
              </Button>
          </div>
        </div>
    </>
  )
}

export default NewSolicitud
