import {
  Button,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import DownArrowIconComponent from '../Icons/DownArrow';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import { useRequerimientosPorSolicitud, useSolicitudPorUnidad } from '../../customHooks/postventa';

import './ProjectListPostVenta.scss';

import { getLogos } from '../../utils/getLogos';

import { colorConfig } from '../../constants/colors';

import Dialog from '../Dialog';
import RequirementView from './RequirementView';
import NewSolicitud from './NewSolicitud';
import {
  confirmSolicitud,
  getActasbyProducto,
  getRequerimientosPostVenta,
  getSolicitudesPostVenta,
  notificarNuevoRequerimiento,
} from '../../services/postventaService';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import { ImagenActa } from '../../constants/endpoints';
import RequestsPostVenta from './RequestsPostVenta';
import FilterRequests from './FilterRequests';
import { useAdditionals, useCliente } from '../PostVenta/customHooks/additionals';


const filterSolicitudes = (solicitudes, additionals) => {
  const units = additionals.filter(adi => adi.unidades.length > 0)
  .map(adi => {
    return adi.unidades.map(unit=> {
      return unit.unidadid_adi;
    })
  }).flat();

  return solicitudes.map(solic=>{
    const filial = solic.filial;
    const proyectos = solic.proyectos.map(proy=>{
      const proyecto = proy.proyecto;
      const unidades = proy.unidades.map(unit=>{
        let solicitudes = unit.solicitudes
        let isManagedAddic = false;
        let additional = additionals.find(adi => adi.unidades.find(uni => uni.unidadid_adi === unit.unidadId)) || null;
        if (units.includes(unit.unidadId)) {
          solicitudes = unit.solicitudes.filter(sol => {
            return sol.estadoSolicitud.toUpperCase().indexOf('CERR') > -1;
          })
          isManagedAddic = true;
        }
        return {
          ...unit,
          isManagedAddic,
          solicitudes,
          adicional: additional?.solicitantenombre_adi || null,
        };
      });
      return {proyecto, unidades};
    });
    return {filial, proyectos};
  })
};

const ProjectListPostVenta = ({ propiedadesPV = [], subsidiary = '', additional , admin }) => {
  const {
    state: { username },
  } = useContext(GlobalContext);

  const { cliente } = useCliente(username);
  const [nameProject, setNameProject] = useState('');
  const [nameUnidad, setNameUnidad] = useState('');
  const [actas, setActas] = useState([]);
  const [canCreateNewRequestNotSent, setCanCreateNewRequestNotSent] = useState(true);
  const [canCreateNewRequestMaxOpen, setCanCreateNewRequestMaxOpen] = useState(true);
  const [showNotSentWarning, setShowNotSentWarning] = useState(false);
  const [notSentWarning, setNotSentWarning] = useState('');
  const [maxOpen, setMaxOpen] = useState(Number.POSITIVE_INFINITY)
  const [showMaxOpenRequestWarning, setShowMaxOpenRequestWarning] = useState(false);
  const { additionals } = useAdditionals(username);
  const { state: { isOwner = false } = {} } = useContext(GlobalContext);

  const [filteredUnits, setFilteredUnits] = useState(propiedadesPV);
  useEffect(() => {
    if (additionals && propiedadesPV) {
      const units = filterSolicitudes(propiedadesPV, additionals);
      setFilteredUnits(units);
    }
  }, [propiedadesPV, additionals]);

  const changeExpandedProyect = id => {
    setUnidadId('');
    setActas([]);
    setUnidades(
      unidades.map(unidad => {
        const unidadProducto = admin ? unidad.unidadId : unidad.producto;
        if (unidadProducto === id) {
          unidad.expanded = !unidad.expanded;
          setProducto(unidad.unidadId);
          setFechaEntrega(unidad.fechaEntrega);
          setFechaMunicipal(unidad.fechaRecepcionMunicipal);
          setNameProject(unidad.proyecto);
          setNameUnidad(unidad.numeroProducto);
          setMaxOpen(unidad.maxOpenedSolic);
          getActasbyProducto(
            unidadProducto,
            ({ data }) => {
              setActas(data);
            },
            err => {
              console.error(err);
            },
          );
        } else {
          unidad.expanded = false;
        }
        return unidad;
      }),
    );
  };

  const handleActa = (id_acta, id_propiedad) => {
    window.open(`${ImagenActa}/${id_propiedad}/${id_acta}`, '_blank');
  };

  const [currentRequirement, setCurrentRequirement] = useState({});
  const [unidades, setUnidades] = useState([]);
  useEffect(() => {
    let unidads = [];
    if (filteredUnits.length > 0) {
      filteredUnits.map(property => {
        property.proyectos.map((project, idx) => {
          project.unidades.map(unidad => {
            unidads.push({
              ...unidad,
              proyecto: project.proyecto,
              filial: property.filial,
              expanded: false,
            });
          });
        });
      });
      setUnidades(unidads);
    }
  }, [filteredUnits]);

  const [filteredSolicitudes, setFilteredSolicitudes] = useState([]);
  const [isManagedAddic, setIsManagedAddic] = useState(false);

  const configureRequests = requests => {
    setFilteredSolicitudes(requests);
    const additional = requests.length > 0 && unidades.find(unidad => unidad.producto = requests[0].producto)?.isManagedAddic || false;
    setIsManagedAddic(additional);
    if (requests.length > 0 && (additional)) {
      const requerimientos = requests.filter(req=>{
        return req.solicitante === `${cliente.nombres} ${cliente.apellidos}` ||
        req.estadoSolicitud.toUpperCase().indexOf('CERRAD') > -1 ||
        req.estadoSolicitud.toUpperCase().indexOf('NO CORRESPONDE') > -1
      });
      setFilteredSolicitudes(requerimientos);
      const units = unidades.map(unidad=>{
        if (unidad.producto === requests[0].producto) {
          return {
            ...unidad,
            solicitudes: requerimientos,
          }
        }
        return unidad;
      });
      setUnidades(units);
    }
    setCanCreateNewRequestMaxOpen(requests.filter(
      request =>
        request.estadoSolicitud.toUpperCase().indexOf('CERRAD') === -1 &&
        request.estadoSolicitud.toUpperCase().indexOf('NO CORRESPONDE') === -1,
    ).length <= maxOpen);
    setShowMaxOpenRequestWarning(false);

    const notSent = requests.find(solicitud => !solicitud.SolicitudConfirmada);
    setCanCreateNewRequestNotSent(!notSent);
    setShowNotSentWarning(false);
    setNotSentWarning(!!notSent && `Para ingresar nuevos requerimientos, seleccione la solicitud pendiente de envío #${notSent.nroSolicitud} y agregue sus nuevos requerimientos`);
  }

  const [unidadSol, setUnidadSol] = useState();
  const [nombreUnidad, setNombreUnidad] = useState();
  const [unidadId, setUnidadId] = useState('');
  const { solicitudes } = useSolicitudPorUnidad(
    unidadId,
    additional ? username : undefined,
    configureRequests,
  );
  useEffect(() => {
    if (unidades.length > 0) {
      unidades.map(unidad => {
        if (unidad.expanded) {
          setUnidadId(unidad.productoId);
          setUnidadSol(unidad.unidadPlanRevisionID);
          setNombreUnidad(unidad.numeroProducto);
        }
      });
    }
  }, [unidades]);

  const [solicitudId, setSolicitudId] = useState('');
  const [nroSolicitud, setNroSolicitud] = useState();
  const [estadoSolicitud, setEstadoSolicitud] = useState();
  const [activeSolicitud, setActiveSolicitud] = useState();
  const { requerimientos, documentos, setRequerimientos } = useRequerimientosPorSolicitud(
    solicitudId,
  );
  useEffect(() => {
    if (filteredSolicitudes.length > 0) {
      filteredSolicitudes.map(solicitud => {
        if (solicitud.expanded) {
          setSolicitudId(solicitud.solicitudId);
          setNroSolicitud(solicitud.nroSolicitud);
          setEstadoSolicitud(!solicitud.SolicitudConfirmada);
          setActiveSolicitud(solicitud);
        }
      });
    }
  }, [filteredSolicitudes]);

  const [notificar, setNotificar] = useState({});
  useEffect(() => {
    setNotificar({
      rut: cliente?.id_Cliente,
      solicitud: {
        nroSolicitud: activeSolicitud?.nroSolicitud,
        solicitante: activeSolicitud?.solicitante,
        numeroProducto: nameUnidad,
        proyecto: nameProject,
        requerimientos,
      },
    });
  }, [requerimientos]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [producto, setProducto] = useState();
  const [showNewSolicitud, setShowNewSolicitud] = useState(false);
  const [titulo, setTitulo] = useState();
  const [fechaEntrega, setFechaEntrega] = useState();
  const [fechaMunicipal, setFechaMunicipal] = useState();
  const [newReq, setNewReq] = useState();
  const handleNewReq = (isReq, newReq) => {
    if (newReq) {
      setCurrentRequirement('');
    }
    if (isReq) {
      setTitulo(`Nuevo requerimiento para la solicitud ${nroSolicitud}`);
    } else {
      setTitulo(`Nueva solicitud para el ${nombreUnidad}`);
    }
    setShowNewSolicitud(true);
  };

  const newRequest = () => {
    if (canCreateNewRequestNotSent && canCreateNewRequestMaxOpen) return handleNewReq(false, true)

    setShowNotSentWarning(!canCreateNewRequestNotSent)
    setShowMaxOpenRequestWarning(!canCreateNewRequestMaxOpen)
  }

  const handleRequirementChange = solicitud => {
    setShowNewSolicitud(false);
    if (!solicitud) return;

    getRequerimientosPostVenta(
      solicitud,
      ({ data }) => {
        setRequerimientos(
          data.requerimientos.map(requerimiento => ({
            ...requerimiento,
            solicitudId,
          })),
        );
      },
      err => {
        console.error(err);
      },
    );
  };

  const ConfirmarSolicitud = solicitud => {
    confirmSolicitud(
      solicitud,
      ({ data }) => {
        notificarNuevoRequerimiento(
          notificar,
          ({ data }) => { },
          error => {
            console.error(error);
          },
        );
        getSolicitudesPostVenta(
          unidadId,
          additional ? username : undefined,
          ({ data }) => {
            configureRequests(
              data.map(solicitud => ({
                ...solicitud,
                expanded: false,
              })),
            );
          },
          err => {
            console.error(err);
          },
        );
      },
      err => {
        console.error(err);
      },
    );
  };
  const handleSolicitudChange = () => {
    setShowNewSolicitud(false);
    getSolicitudesPostVenta(
      unidadId,
      additional ? username : undefined,
      ({ data }) => {
        configureRequests(
          data.map(solicitud => ({
            ...solicitud,
            expanded: false,
          })),
        );
      },
      err => {
        console.error(err);
      },
    );
  };

  return (
    <>
      <Dialog
        open={showNewSolicitud}
        className="dialog-new"
        onClose={() => handleRequirementChange(solicitudId)}
        maxWidth="lg"
        scroll="paper"
        classes={{ paper: 'dialog-new' }}
      >
        <DialogContent>
          <NewSolicitud
            subsidiary={subsidiary}
            unidad={unidadSol}
            handleClose={() => handleRequirementChange(solicitudId)}
            titulo={titulo}
            unidadId={producto}
            solicitud={activeSolicitud}
            fechaEntrega={fechaEntrega}
            fechaMunicipal={fechaMunicipal}
            requirement={currentRequirement}
            onNewSolicitud={handleSolicitudChange}
            cliente={cliente}
            proyecto={nameProject}
            nombreUnidad={nameUnidad}
            requerimientos={requerimientos}
            onChangeRequerimientos={setNewReq}
            additional={admin ? true : additional}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={dialogOpen}
        className="dialog-view"
        onClose={() => setDialogOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        scroll="paper"
        classes={{ paper: 'dialog-view' }}
      >
        <RequirementView
          requirement={currentRequirement}
          handleClose={() => setDialogOpen(false)}
          subsidiary={subsidiary}
        />
      </Dialog>

      <div className="ProjectList">
        {unidades.length > 0 &&
          unidades.map((unidad, index) => {
            return (
              <div
                className="ProjectList__container"
                style={{ backgroundColor: colorConfig[subsidiary].secondary }}
              >
                <ExpansionPanel
                  key={unidad.unidadId}
                  expanded={unidad.expanded}
                  onChange={() => {
                    if (admin) {
                      changeExpandedProyect(unidad.unidadId);
                    } else {
                      changeExpandedProyect(unidad.producto);
                    }
                  }}
                  style={{ boxShadow: 'none' }}
                >
                  <ExpansionPanelSummary
                    expandIcon={<DownArrowIconComponent color={colorConfig[subsidiary].icons} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ border: '1px solid', borderRadius: '5px' }}
                  >
                    <div className="ProjectDetailPV__expansionPanelSummary">
                      <span className="ProjectDetailPV__expansionPanelSummary__UnitName">
                        <div>
                          <img
                            alt={`Icono ${unidad.filial}`}
                            className="ProjectDetailPV__expansionPanelSummary__Icono"
                            {...getLogos(unidad.filial.split(' ')[0].toLowerCase(), false, true)}
                          />
                          <div className="ProjectDetailPV__expansionPanelSummary__UnitName__ProyD">
                            {unidad.proyecto} - {unidad.numeroProducto}
                          </div>
                          <div className="ProjectDetailPV__expansionPanelSummary__UnitName__ProyM">
                            {unidad.proyecto}
                            <br />
                            {unidad.numeroProducto}
                          </div>
                        </div>
                        <span>({unidad.expanded ? filteredSolicitudes.length : unidad.solicitudes.length} solicitudes)</span>
                      </span>
                      {!unidad.expanded && (
                        <>
                          <span className="ProjectDetailPV__expansionPanelSummary__addressName">
                            {unidad.direccion}
                          </span>
                          <span className="ProjectDetailPV__expansionPanelSummary__addressName">
                            Fecha Entrega: {unidad.fechaEntrega}
                          </span>
                          <span className="ProjectDetailPV__expansionPanelSummary__addressName">
                            Fecha Recepción Municipal: {unidad.fechaRecepcionMunicipal}
                          </span>
                        </>
                      )}
                    </div>
                  </ExpansionPanelSummary>
                  <div className="ProjectDetailPV__expansionPanelSummary__barra-tareas">
                    {unidad.isManagedAddic ? (
                      <Typography className="ProjectDetailPV__expansionPanelSummary__barra-tareas__text">
                        Unidad administrada por {unidad.adicional || 'Adicional'}
                      </Typography>
                    )
                    : (
                      <Button
                        onClick={() => newRequest()}
                        style={{ color: colorConfig[subsidiary].icons, textTransform: 'none' }}
                        variant="outlined"
                      >
                          <InsertDriveFileOutlinedIcon color={colorConfig[subsidiary].icons} />
                          Nueva solicitud
                      </Button>
                    )}
                    <div>
                      <FilterRequests requests={solicitudes} setRequests={setFilteredSolicitudes} />
                    </div>
                  </div>
                  {showNotSentWarning && (
                    <div className="ProjectDetailPV__expansionPanelSummary__barra-tareas">
                      <span className='warning'>{notSentWarning}</span>
                    </div>
                  )}
                  {showMaxOpenRequestWarning && (
                    <div className="ProjectDetailPV__expansionPanelSummary__barra-tareas">
                      <span className='warning'>
                        <p>Usted ha superado el máximo de {maxOpen} Solicitud Abiertas en Postventa <span className='capitalize'>{subsidiary}</span></p>
                        <p>Las solicitudes que están actualmente en proceso deben ser completados sus trabajos y cerradas para que usted pueda Generar Nuevas Solicitudes</p>
                      </span>
                    </div>
                  )}
                  <ExpansionPanelDetails>
                    <div className="ProjectDetailPV">
                      <RequestsPostVenta
                        requests={filteredSolicitudes}
                        subsidiary={subsidiary}
                        handleNewReq={handleNewReq}
                        ConfirmarSolicitud={ConfirmarSolicitud}
                        setDialogOpen={setDialogOpen}
                        setSelectedRequirement={setCurrentRequirement}
                        initialRequestQuantity={10}
                        requerimientos={requerimientos}
                        documentos={documentos}
                        setSelectedRequest={setSolicitudId}
                        setActiveSolicitud={setActiveSolicitud}
                        setNroSolicitud={setNroSolicitud}
                      />
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ProjectListPostVenta;
