import React, {useEffect, useState} from "react";

import { IconButton } from "@material-ui/core";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import LeftArrowIcon from "../Icons/LeftArrowIcon/LeftArrowIcon";
import RightArrowIcon from "../Icons/RightArrowIcon/RightArrowIcon";
import CloseButtonIcon from "../Icons/CloseButtonIcon/CloseButtonIcon";
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import Dialog from '../Dialog';

import './RequirementView.scss';
import { colorConfig } from "../../constants/colors";
import { useImagenesPorRequerimiento } from "../../customHooks/postventa";

const RequirementView = ({ requirement, imgs = [], handleClose, subsidiary }) => {
  const { imagenes } = useImagenesPorRequerimiento(requirement.requerimientoId);
  const [currentImage, setCurrentImage] = useState(0);
  const handleLeftArrowClick = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    } else {
      setCurrentImage(images.length - 1);
    }
  }
  const handleRightArrowClick = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage(currentImage + 1);
    } else {
      setCurrentImage(0);
    }
  }

  // images = ['https://socointranetclientes.blob.core.windows.net/socovesa-dev/images/requirement1.png',
  // 'https://socointranetclientes.blob.core.windows.net/socovesa-dev/images/requirement2.png',
  // 'https://socointranetclientes.blob.core.windows.net/socovesa-dev/images/requirement3.png',
  // 'https://socointranetclientes.blob.core.windows.net/socovesa-dev/images/requirement4.png',
  // 'https://socointranetclientes.blob.core.windows.net/socovesa-dev/images/requirement5.png']

  const [images, setImages] = useState([]);
  useEffect(() => {
    if (imagenes && imagenes.length > 0) {
      setImages(imagenes.map(image => `${image.url}?${Math.random()}`));
    }
  }, [imagenes]);

  const [showZoom, setShowZoom] = useState(false);


  return (
    <>
      <div className='RequirementView__header'>
        <Dialog
          open={showZoom}
          onClose={() => setShowZoom(false)}
          fullWidth={true}
          maxWidth={'lg'}
          >
            {images.length > 0 && (
              <img
                src={images[currentImage]}
                className="view_zoom"
                onClick={() => setShowZoom(false)}
              />
            )}
        </Dialog>
        <div className="RequirementView__header__title">
          <span className="RequirementView__header__title__req">Requerimiento {requirement.componente}</span>
          <span className="RequirementView__header__title__mid">-</span>
          <span className="RequirementView__header__title__est">
            <span className="RequirementView__header__title__est__icon">
              {requirement.estado.toUpperCase().indexOf('SOLUCI') !== -1 ?
                <CheckCircleIcon htmlColor='olivedrab' fontSize='small' />
                : <WatchLaterIcon htmlColor='goldenrod' fontSize='small' />
              }
            </span>
            <span className="RequirementView__header__title__est__ado">
                {requirement.estadoOV || requirement.estadoOT || requirement.estado}
            </span>
          </span>
        </div>
      </div>
      <div className="RequirementView">
        <div className='image-view'>
          {images.length > 0 && (
            <ImageView
                image={images[currentImage]}
                next={handleRightArrowClick}
                prev={handleLeftArrowClick}
                zoom={()=>setShowZoom(true)}
            />
          )}
        </div>
        <div className='requirement-view__content'>
          <p><strong>Recinto: </strong><span>{requirement.recintoEspecifico}</span></p>
          <p><strong>Elemento: </strong><span>{requirement.elemento}</span></p>
          <p><strong>Componente: </strong><span>{requirement.componente}</span></p>
          <p><strong>Falla: </strong><span>{requirement.falla}</span></p>
          <p><strong>Detalle: </strong><span>{requirement.descripcionRequerimiento}</span></p>
          <p><strong>Estado: </strong>
            <span>
              {requirement.estado.toUpperCase().indexOf('SOLUCI') !== -1 ?
                <CheckCircleIcon htmlColor='olivedrab' fontSize='small' />
                : <WatchLaterIcon htmlColor='goldenrod' fontSize='small' />
              }
              {requirement.estadoOV || requirement.estadoOT || requirement.estado}
            </span>
          </p>
        </div>
        <IconButton
          className='requirement-view__close-button'
          onClick={handleClose}
          style={{position: 'absolute'}}
        >
          <CloseButtonIcon color={colorConfig[subsidiary].icons} />
        </IconButton>
      </div>
    </>
  )
};

const ImageView = ({ image, next, prev, zoom }) => {

  return (
    <div className='image-view__image'>
      {/* <img
          src={image}
          className='image-view__image__img'
          onMouseOver={(e) => {e.currentTarget.style = {transform: 'scale(1.7)', overflow: 'hidden'}}}
          onMouseOut={(e) => {e.currentTarget.style = {transform: 'scale(1)', overflow: 'hidden'}}}
      /> */}

      <TransformWrapper
        initialScale={1}
        initialPosition={{x: 0, y: 0}}
      >
        <TransformComponent
          contentClass='image-view__image__img'
        >
          <img
            src={image}
            className='image-view__image__img'
          />
        </TransformComponent>
      </TransformWrapper>

      <IconButton
        onClick={prev}
        className='image-view__image__left-arrow'
        style={{position: 'absolute'}}
      >
        <LeftArrowIcon color="white"/>
      </IconButton>
      <IconButton
        onClick={next}
        className='image-view__image__right-arrow'
        style={{position: 'absolute'}}
      >
        <RightArrowIcon color="white"/>
      </IconButton>
      {/* <IconButton
        className='image-view__image__zoom-button'
        style={{position: 'absolute'}}
        onClick={zoom}
      >
        <ZoomIcon color="white"/>
      </IconButton> */}
    </div>
  );
};


export default RequirementView;
